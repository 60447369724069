import React, { Component } from "react";
import { NotionRenderer } from "react-notion";

import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import "react-notion/src/styles.css";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blockMap: null,
    };
  }

  async componentDidMount() {
    const pageId =
      window.location.pathname == "/"
        ? "523fd1aff1454b51a489b3a5cfa3d923"
        : window.location.pathname.split("/").pop();

    const data = await fetch(
      "https://notion-api.splitbee.io/v1/page/" + pageId
    ).then((res) => res.json());

    this.setState({ blockMap: data });
  }

  render() {
    return (
      <div>
        {this.state.blockMap == null ? (
          ""
        ) : (
          <div className="px-4 pt-10 md:pt-15 pb-20 mx-auto max-w-3xl">
            <NotionRenderer blockMap={this.state.blockMap} />
          </div>
        )}
      </div>

      // <div class=" bg-graphics-lt home">
      //     <div class="max-w-5xl mx-auto sm:px-20 px-6 ">
      //         <div class="py-10 md:py-0 md:h-screen flex items-center">
      //             <div>

      //                 <div class="flex items-center" data-aos="fade-up" data-aos-duration="2000">
      //                     <p class="flex-1 leading-none text-lg md:text-2xl">Aariz Irfan <span class="font-thin"
      //                     >· Los Angeles, CA</span></p>

      //                     <div>
      //                         <div class="flex -mx-4">
      //                             <a href="#mcguy"
      //                                 class="cursor-pointer mx-4 border-b-2 border-gray-800 hover:border-black text-gray-800 hover:text-black leading-none text-sm md:text-base tracking-widest uppercase"
      //                             >
      //                                 McGuy
      //     </a>

      //                             <a href="#interests"
      //                                 class="cursor-pointer mx-4 border-b-2 border-gray-800 hover:border-black text-gray-800 hover:text-black leading-none text-sm md:text-base tracking-widest uppercase"
      //                             >
      //                                 Interests
      //     </a>
      //                         </div>
      //                     </div>
      //                 </div>

      //                 <p class="mt-12 font-bold leading-none text-green-400 text-5xl md:text-7xl"
      //                     data-aos="fade-up" data-aos-duration="2000"
      //                     data-aos-delay="1000">
      //                     Innovator, Designer, <br />Leader.</p>

      //                 <p class="md:mt-10 mt-8 leading-tight text-xl md:text-4xl"
      //                     data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2500">A ninth
      //                     grader at
      //                     Harvard-Westlake who loves creating, teaching, designing, and learning. </p>

      //                 <div class="flex" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2500">
      //                     <a href="#mcguy" class="border-b-2 border-black md:mt-16 mt-12 leading-none text-md tracking-widest">
      //                         LEARN MORE
      //                         <span class="text-2xl ml-1">&#8642;</span>
      //                     </a>
      //                 </div>
      //             </div>
      //         </div>

      //         <div id="mcguy" class="mt-20 pb-10 flex items-center">
      //             <div>

      //                 <p class="flex-1 leading-none uppercase text-5xl md:text-6xl text-green-400"><a class="text-black hover: cursor-pointer border-black"
      //                     href="https://www.youtube.com/channel/UC2MtlTiLxWNQAjHyFZt95Vw" target="_blank" rel="noopener noreferrer">McGuy</a></p>
      //                 <p class="uppercase text-lg text-green-400">My YouTube Channel</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl">
      //                     Purpose</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl">The
      //                 purpose of my YouTube channel is to educate those interested in computer science and programming.
      //                 Specifically, my channel is more devoted to beginners and younger kids who want to learn the basics of
      //                 coding. I teach the fundamentals, individual tutorials, and ways for beginners to have fun with computer
      //                 science.</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl"
      //                 >
      //                     History</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl">I
      //                 started my YouTube channel in 2013. Although I was young, I had a passion to teach and help others with a
      //                 topic that I loved. Creating a channel combined a lot of my passions: filmmaking, programming, and teaching.
      //                 Over the years, I have uploaded videos and have now gained tens of thousands of subscribers. My videos,
      //                 collectively, have been viewed millions of times. Although these statistics are shocking and exciting, what
      //                 truly motivates me is helping other people learn.</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl"
      //                 >
      //                     Future</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl">I want
      //                 to continue to focus on computer science with my YouTube channel. However, I do want to shift over to more
      //                 advanced syntax based coding languages. This may include Python, Java, C#, C++, and more way for viewers to
      //                 get involved with coding.</p>
      //             </div>
      //         </div>

      //         <div id="interests" class="py-24 flex items-center">
      //             <div>
      //                 <p class="flex-1 leading-none text-5xl md:text-6xl text-black">My Interests</p>

      //                 <p class="uppercase text-lg text-green-400" >Just about me</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl"
      //                 >
      //                     Coding</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl" > I first
      //                 joined Scratch at the age of 7, and immediately fell in love with it. The ability to create, with a freedom
      //                 that's unprecedented consistently motivates me to work on coding passion projects. Whether I'm coding
      //                 for myself, for my friends, family, or even my school, creating really anything on a computer has always
      //                 been an artistic outlet for me.
      //                 I've been able to utilize this skill at hackathons and other team projects, and it's never failed to bring
      //                 joy to me and other around me. A true passion, for sure.</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl"
      //                 >
      //                     Tennis</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl">Tennis
      //                 is something I love to do as both a hobby, and as a way to get to be competitive among other junior tennis
      //                 players. Competing in tournaments over the weekends is something I am constantly looking forward to, and
      //                 it is when my hard work practicing finally shows results. I've been playing since the age of 8, and I love
      //                 it.</p>

      //                 <p class="text-gray-800 md:mt-16 mt-12 leading-none text-md tracking-widest uppercase text-xl"
      //                 >
      //                     Piano</p>

      //                 <p class="text-gray-500 mt-4 leading-tight text-lg md:text-2xl"> I
      //                 started playing piano at the age of 6, and I've always thought of myself as somewhat musical. I love
      //                 learning and devouring new compositions by the likes of Chopin, Rachmaninoff, and Bach. I can't stop
      //                 listening to classical music either. Piano is definitely something I'm super passionate about, and I know
      //                 I'll be playing it for a long, long time.</p>

      //             </div>
      //         </div>

      //         <div>
      //             <p class="text-sm font-bold text-right pb-10 text-green-400">Made by Aariz Irfan. <a
      //                 class="text-green-600" href="mailto:aarizirf@gmail.com">Contact me</a></p>
      //         </div>
      //     </div>
      // </div>
    );
  }
}

export default HomePage;
