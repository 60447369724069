import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";

import ROUTES from "./constants/routes";

import HomePage from "./pages/HomePage";
import ZabaanPage from "./pages/ZabaanPage";
import WhitakersWordsPage from "./pages/WhitakersWordsPage";

import "./stylesheets/index.css";
import "aos/dist/aos.css";

AOS.init();

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/edit"
            exact
            component={() => {
              window.location.href = ROUTES.edit;
              return null;
            }}
          />

          <Route
            path={ROUTES.zabaan}
            exact
            render={(props) => <ZabaanPage {...props} />}
          />

          <Route
            path={ROUTES.whitakersWords}
            exact
            render={(props) => <WhitakersWordsPage {...props} />}
          />

          <Route
            path={ROUTES.home}
            render={(props) => <HomePage {...props} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
