import React, { Component } from "react";
import logo from "../assets/zabaan-word-black.png";
import mockup from "../assets/zabaan-mockup-clear.png";

class ZabaanPage extends Component {
  render() {
    const style = {
      fontFamily: '"Work Sans", sans-serif',
    };

    return (
      <div style={style}>
        <div className="h-screen flex-col flex bg-graphics">
          <div data-aos="fade=in" data-aos-duration="2000">
            <div className="max-w-5xl px-6 flex mx-auto py-6">
              <div className="flex-1 flex justify-center">
                <img src={logo} className="h-8 sm:h-10" alt="Logo." />
                {/* <p className="font-medium text-2xl">Zabaan</p> */}
              </div>
            </div>
          </div>

          <div className="flex-grow lg:flex items-center lg:-mt-10 lg:px-6">
            <div className="lg:flex items-center justify-center">
              <div
                className="lg:w-1/2 lg:flex justify-end px-6 lg:px-0"
                data-aos="fade-up"
                data-aos-duration="2500"
                data-aos-delay="1000"
              >
                <div className="lg:max-w-xl">
                  <p className="text-5xl sm:text-6xl lg:text-7xl font-medium leading-none">
                    Transliterate <br />
                    <span className="text-primary">with ease</span>
                  </p>
                  <p className="text-xl md:text-3xl font-light mt-5 leading-tight text-gray-900">
                    Zabaan allows you to easily transliterate between English
                    (Roman) and Urdu text.
                  </p>
                  {/* eslint-disable-next-line */}
                  <a
                    target="_blank"
                    href="https://apps.apple.com/gb/app/zabaan-urdu-transliterator/id1513127678#?platform=iphone"
                    className="ios-badge mt-5"
                  ></a>
                </div>
              </div>

              <div
                className="lg:w-1/2 lg:max-w-6xl md:max-w-2xl max-w-xs mx-auto lg:mx-0"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="2000"
              >
                <img src={mockup} alt="Mockup." className="img-shadow" />
              </div>
            </div>
          </div>
        </div>

        <div className="py-20 bg-gray-800">
          <div className="mx-auto max-w-xl px-6 md:px-0">
            <div>
              <p className="up-on-hover cursor-pointer text-gray-400 hover:text-white transition font-bold uppercase">
                <a
                  href="https://forms.gle/xTYWtVifFDkVhRsB7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us.
                </a>
              </p>
              <p className="text-gray-600 hover:text-gray-500 transition font-bold uppercase">
                Copyright &copy; Furniturewala Brothers 2020.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ZabaanPage;
