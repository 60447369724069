import React, { Component } from 'react';

import screenshot from "../assets/whitakers-ss.png"
import logo from "../assets/wwicon1024pt.png"

class WhitakersWordsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="">
                <div className="ww-bg py-20 md:py-32 smd:h-screen md:flex items-center justify-center">
                    <div className="w-3/7 md:mx-8 flex justify-end hidden md:block">
                        <img className="w-64 rounded-lg shadow-xl" src={screenshot} />
                    </div>

                    <div className="w-4/7 md:mx-8 px-6 md:px-0">
                        <div>
                            <img src={logo} className="w-32 md:w-20 rounded-lg shadow-2xl" />
                        </div>
                        <div className="mt-10 font-lato">
                            <p className="text-5xl md:text-6xl leading-none text-white font-bold">Whitaker's Words</p>
                            <p className="text-3xl leading-tight text-gray-300 mt-2">A simple, easy-to-use, latin dictionary.</p>
                        </div>
                        <div className="mt-1">
                            <a target="_blank" href="https://apps.apple.com/gb/app/zabaan-urdu-transliterator/id1513127678#?platform=iphone" className="ios-badge mt-5 shadow"></a>
                        </div>
                    </div>
                </div>
                <div className="bg-graphics-lt">
                    <div className="py-20 md:py-32 max-w-6xl px-6 md:px-12 mx-auto">
                        <div>
                            <p className="font-light text-lg ww-text tracking-widest mb-3 uppercase">Our features</p>
                            <p className="text-5xl font-bold font-lato max-w-xl leading-tight">Why our app is the perfect fit for you.</p>
                        </div>

                        <div className="mt-16 md:mt-10">
                            <div className="md:flex md:-mx-8 -mt-8 md:mt-0 items-start justify-center">
                                <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                    <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/1584/1584892.svg" />

                                    <div className="mt-4">
                                        <p className="font-medium text-gray-900 text-xl">Rapid translation</p>
                                        <p className="font-light text-gray-600 text-md mt-3">Our engine is faster than ever, bringing you accurate definitions within <em>milliseconds</em> of searching.</p>
                                    </div>
                                </div>

                                <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                    <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/3043/3043927.svg" />

                                    <div className="mt-4">
                                        <p className="font-medium text-gray-900 text-xl">Easy searching</p>
                                        <p className="font-light text-gray-600 text-md mt-3">We look through tens of thousands of common searches to bring you the best results as quickly as possible.</p>
                                    </div>
                                </div>

                                <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                    <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/864/864685.svg" />

                                    <div className="mt-4">
                                        <p className="font-medium text-gray-900 text-xl">Accurate definitions</p>
                                        <p className="font-light text-gray-600 text-md mt-3">Whitaker's Words uses the best resource for Latin translation in the modern era.</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="py-10 md:py-20 max-w-6xl mx-auto px-6 md:px-12 border border-gray-200 rounded-lg bg-white shadow-xl">
                        <div>
                            <p className="font-medium text-lg">Whitaker's Words &copy; 2020</p>
                            <a className="flex text-gray-600 transition hover:text-gray-700 up-on-hover mt-5" target="_blank" href="mailto:contact@walaventures.com">Contact us</a>
                            <a className="flex text-gray-600 transition hover:text-gray-700 up-on-hover mt-2" target="_blank" href="https://apps.apple.com/gb/app/zabaan-urdu-transliterator/id1513127678#?platform=iphone">Download on the iOS App Store</a>
                            <a className="flex text-gray-400 mt-2">See you later! 👋</a>
                        </div>
                    </div>
                </div>

                
            </div>
        );
    }
}

export default WhitakersWordsPage;